import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/bigcommerce/PrivateRoute"
import Profile from "./profile"
import Signin from "./signin"

const App = () => (
  <Layout>
    <div className="page-headline">
      <Router>
        <PrivateRoute path="/app/profile" component={Profile} />
        <Signin path="/app/signin" />
      </Router>
    </div>
  </Layout>
)

export default App
