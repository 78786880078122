import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import React, { useContext, useEffect, useState } from "react"
import CustomerContext from "../context/CustomerProvider"

const Signin = () => {
  const value = useContext(CustomerContext)
  const signinCustomer = value?.signinCustomer
  const isFetching = value?.isFetching
  const loading = value?.loading
  const customer = value?.customer

  const [fields, setFields] = useState({
    email: "",
    password: "",
  })

  useEffect(() => {
    if (customer) navigate(`/app/profile`)
  }, [customer])

  function handleChange({ target }) {
    const { name, value } = target
    setFields(prev => ({ ...prev, [name]: value }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    signinCustomer({
      email: fields.email,
      password: fields.password,
    })
  }

  if (loading) return <p className="text-center">Please wait..</p>

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label htmlFor="email">Email Address</label>
              <input
                name="email"
                value={fields.email}
                onChange={handleChange}
                type="email"
                className="form-control"
                id="email"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                value={fields.password}
                onChange={handleChange}
                type="password"
                className="form-control"
                id="password"
                required
              />
              <Link to="/reset-password" className="text-primary">
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              className="btn btn-dark"
              disabled={isFetching}
            >
              {isFetching ? "Signing in.." : "Sign In"}
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <div className="bg-white shadow-sm rounded p-3">
            <h3>New Customer?</h3>
            <p>Create an account with us and you'll be able to:</p>
            <ul>
              <li>Checkout faster</li>
              <li>Save multiple shipping addresses</li>
              <li>Access your order history</li>
              <li>Track new orders</li>
            </ul>
            <Link to="/app/register" className="btn btn-dark">
              Create Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin
