import React, { useContext } from "react"
import { navigate } from "gatsby"
import CustomerContext from "../../context/CustomerProvider"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const customer_value = useContext(CustomerContext)
  const customer = customer_value?.customer

  if (!customer && location.pathname !== `/app/login`) {
    navigate("/app/signin")
    return null
  }

  return <Component {...rest} customer_value={customer_value} />
}

export default PrivateRoute
